@import '../../../scss/theme-bootstrap';

.header-search {
  padding: 0 20px;
  position: relative;
  top: 0;
  width: 100%;
  scrollbar-color: $color-white $color-core-black;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-white;
    border-radius: 20px;
  }
  @include breakpoint($medium-up) {
    @include swap_direction(padding, 0 15px 0 #{$container-pad-landscape});
    position: fixed;
    height: calc(100% - #{$header-nav-top-pos});
    margin: #{$header-nav-top-pos} auto 0;
    width: 99%;
    overflow-y: auto;
  }
  @include breakpoint($landscape-only) {
    width: 100%;
    max-width: 702px;
  }
  .search-form {
    background: transparent;
    min-height: auto;
    padding: 0;
    width: 100%;
    &__fields {
      @include clearfix;
    }
    input[type='text'].search-form__field {
      @include typography-headline--m();
      border: none;
      border-bottom: 1px solid $color-metal-gray;
      color: $color-white;
      line-height: 1.2;
      padding: 0;
      text-transform: none;
      width: 100%;
      @include breakpoint($portrait-up) {
        line-height: get-line-height(24px, 29px);
      }
    }
  }
  .search-results {
    margin-top: 15px;
    padding: 0;
    @include breakpoint($medium-up) {
      margin-top: 20px;
    }
    &__suggestions-title {
      @include typography-body-text();
      margin-bottom: 10px;
      opacity: 0.5;
    }
  }
  .search-suggestions__link {
    @include typography-link-text();
    color: $color-white;
    text-decoration: none;
  }
  .popular-search-wrapper {
    .search-suggestions__item,
    .search-suggestions__link {
      line-height: 1;
      @include breakpoint($medium-up) {
        line-height: 1.3;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .typeahead-wrapper {
    margin-top: 4px;
    .search-suggestions__item {
      @include typography-body-text();
      padding-bottom: 10px;
      &.last {
        padding: 0;
      }
    }
    .term-results {
      .search-suggestions__item,
      .search-suggestions__link {
        line-height: 1;
      }
    }
    .see-results {
      margin: 30px 0 15px;
      .search-count {
        opacity: 0.5;
        text-decoration: underline;
      }
    }
    .product-results {
      display: grid;
      @include breakpoint($medium-up) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .product {
        padding-bottom: 16px;
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 0 25px 20px 0);
          width: min-content;
        }
        &:nth-child(2n) {
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 0 20px 0);
          }
        }
        .product__image-wrapper {
          background-color: $color-white;
          display: block;
          min-height: 310px;
          min-width: 310px;
        }
        .product__abstract {
          @include typography-body-text();
          margin-top: 10px;
          .product__headline {
            margin-bottom: 5px;
          }
          a {
            color: $color-white;
            text-decoration: none;
          }
          .product__short-desc,
          .product__sku-info {
            margin-bottom: 3px;
            opacity: 0.5;
          }
          .seperator {
            vertical-align: super;
          }
          .product_result_header,
          .product__description {
            @include typography-body-text();
          }
          .product__description {
            color: $color-white-op50;
          }
        }
      }
    }
  }
  .popular-search-wrapper {
    .search-suggestions__item {
      @include typography-body-text();
      padding-bottom: 10px;
    }
  }
}
